import React, { useEffect, useLayoutEffect } from 'react'
import { Tab, initTE, } from "tw-elements";
import ViewSports from './components/ViewSports';
import { useLocation } from 'react-router-dom';
import ViewQuiz from './components/ViewQuiz';

const ViewQuizAndSports = ({activeSport, setActiveSport, activeQuiz, setActiveQuiz}) => {

 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);
 const tab = queryParams.get('tab');

 useEffect( () => initTE({ Tab }) , [] )

 useLayoutEffect( () => {
   if(tab === 'quiz') document.querySelector('#quiz-tab').click()
   else document.querySelector('#sports-tab').click()
 } , [] )

  return (
    <div>
        <header>
             <h5 className='text-lg'>
                 Quiz & Inter House Sports 
             </h5>
        </header>
        <section>
        <ul
        className="mb-5 mt-2 flex list-none flex-row flex-wrap border-b border-b-2 border-gray-200 pl-0"
        role="tablist"
        data-te-nav-ref
       >
              <li role="presentation">
                    <a
                      href="#tabs-home"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px] font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 data-[te-nav-active]:bg-white  focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-home"
                      data-te-nav-active
                      role="tab"
                      aria-controls="tabs-home"
                      aria-selected="true"
                      id='sports-tab'
                      > Inter House Sports </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-profile"
                      className="my-2 block data-[te-nav-active]:bg-white border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px]  font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-profile"
                      role="tab"
                      id='quiz-tab'
                      aria-controls="tabs-profile"
                      aria-selected="false"
                      > Quiz Overview </a>
                  </li>
        </ul>
        <div className="mb-6">
                <div
                  className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                  data-te-tab-active
                >
                 <section>
                    <ViewSports activeSport={activeSport} setActiveSport={setActiveSport} />
                 </section>
                </div>
                <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-profile"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tab">
                       <ViewQuiz activeQuiz={activeQuiz} setActiveQuiz={setActiveQuiz} />
                  </div>
          </div>
        </section>
    </div>
  )
}

export default ViewQuizAndSports