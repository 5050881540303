import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../../components/Buttons/Button'
import EmptyData from '../../../../../../../../components/EmptyData'
import { api } from '../../../../../../../../api/quiz-and-sport';
import { useQuery } from 'react-query';
import Search from '../../../../../../../../components/Inputs/Search';
import Loader from '../../../../../../../../components/loader/Loader';
import Table from '../../../../../../../../components/table/Table';
import Pagination from '../../../../../../../../components/pagination/Pagination';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useNavigate } from 'react-router-dom';
import ViewSportTeams from './ViewSportTeams';

const ViewSports = ({activeSport, setActiveSport}) => {
    
    const [perPage, setPerPage] = useState(5);
    const [openModal, setOpenModal] = useState(false)
    const [activeSportTeams, setActiveSportTeams] = useState([])
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const key = "view sports"
    const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
    const navigate = useNavigate()
    const { data: sports, isLoading, refetch } = useQuery(
        [key, { perPage, currentPage, search, orgId }],
        ({queryKey}) => { 
            return api.getSports(queryKey[1]);
        },
        {
            cacheTime: 100,
            staleTime: 100,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
      });
    
      useEffect( () => {
        refetch({ queryKey: [ key, { perPage, currentPage, search, orgId }] })
      }, [ currentPage, perPage, ])

      let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index +
            1 +
            ((sports?.meta?.page || 1) - 1) * (sports?.meta?.perPage || perPage ),
        },
        {
            title: "Sport Name",
            selector: "name",
            key: "name",
            render: ({ item }) => {                      
                 return( 
                    <span className="">{item}</span>
                 )
             },
          },
        {
          title: "Sport Teams",
          selector: null,
          key: null,
          render: ({ row }) => {                      
               return( 
                  <span className="pl-3">
                    {row?.sportTeams?.length }
                  </span>
               )
           },
        },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Action",
          selector: null,
          key: null,
          render: ({row}) => {           
            return <div > 
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                        <button className="ml-2 bi bi-three-dots-vertical" aria-label="Customise options">
                        </button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        <DropdownMenu.Item
                            className="DropdownMenuItem text-left hover:text-white text-gray-900"
                            onClick={ () => {
                                 setActiveSportTeams(row)
                                 setOpenModal(true)
                            } }
                        >
                            View Sport Teams
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator" />
                        <DropdownMenu.Item 
                            className="DropdownMenuItem text-left hover:text-white text-gray-900"
                            onClick={ () => {
                                setActiveSport(row)
                                navigate('/admin/learners/quiz-and-sports/create-sport')
                            } }
                        >
                            Edit Sport
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator" />
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                 </DropdownMenu.Root>
             </div>
          },
        },
      ],
      [1, 20, 20]
    );
    
  const tableProps = { isLoading, columns, data: sports?.data }

  return (
    <div className='bg-white p-3 pt-2 rounded-lg'>
        <ViewSportTeams 
           openModal={openModal} setOpenModal={setOpenModal}
           activeSport={activeSportTeams}
         />
        <header className='flex items-center justify-between'>
             <div className='flex items-center'>
             <h5 className='text-sm -mt-2'> 
               Inter House Sports ({sports?.meta?.total}) &nbsp;&nbsp;&nbsp;&nbsp;
             </h5>
                  <div className='pt-2'>
                     <Search 
                            width={'w-[250px]'} placeholder={'Search Sport..'}
                            onChange={ event =>  {
                                setSearch(event.target.value)
                                refetch({ 
                                    queryKey: [ key, { perPage, currentPage, orgId, search: event.target.value }] 
                                })
                            }}  
                    />
                  </div>
             </div>
             <Button 
               width={'w-[200px]'}
               text={'Create New Inter House Sport'}
               bgColor={'bg-secondary'}
               renderAs='link'
               to='/admin/learners/quiz-and-sports/create-sport'
               onClick={setActiveSport(null)}
             />
        </header>
        <section>
             {
                isLoading ? <Loader /> :
                 sports?.data.length ? 
                  <Table {...tableProps} />
                 :
                  <EmptyData text={'There is no any inter house sports available yet.'} />
             } 
        </section>
         { !isLoading &&
            <Pagination 
                data={sports?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
         />
         }
    </div>
  )
}
export default ViewSports