import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CustomToastProvider } from './components/customtoast/CustomToastProvider';
import ErrorBoundary from './components/errorBoundary/errorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <ErrorBoundary>
        <CustomToastProvider>
            <QueryClientProvider client={queryClient}>
                <Router>
                   <App />
                </Router>
            </QueryClientProvider>
        </CustomToastProvider>
     </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
